/*--------------------------
    split Area Styles  
----------------------------*/

.rn-splite-style {
    margin-top: 132px;
    margin-bottom: 132px;
    .thumbnail {
        img {
            border-radius: 0;
            width: 100%;
        }
        &.image-left-content {
            img {
                border-radius: 0 10px 10px 0;
            }
        }
    }
    .split-inner {
        padding: 30px 115px;

        @media #{$lg-layout} {
            padding: 40px 80px;
        }
        @media #{$md-layout} {
            padding: 40px 30px;
        }
        @media #{$sm-layout} {
            padding: 40px 30px;
        }
        
        .title {
            font-size: 36px;
            line-height: 50px;
            color: var(--color-heading);
            font-weight: var(--p-bold);
            @media #{$sm-layout} {
                font-size: 24px;
                line-height: 30px;
            }
        }
        .split-list {
            list-style: none;
            padding-left: 0;
            margin: 0;
            li {
                font-size: 16px;
                color: var(--color-dark-alt);
            }
        }
    }
}

.no-radius .rn-splite-style .thumbnail.image-left-content img {
    border-radius: 0;
}






